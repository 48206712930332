/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useInfoViewActionsContext } from "@crema/context/AppContextProvider/InfoViewContextProvider";
import jwtAxios from "@crema/services/axios";
import { isRequestSuccessful, sanitizeData } from "@crema/helpers/ApiHelper";
import { APP_CONFIG } from "../../@crema/constants/AppConfig";
import { AppStateConstant } from "@crema/constants/AppState";

import { message } from "antd";
import { HTTPStatusCodeMessage } from "@crema/enum/httpStatus.enum";
import { isNullOrEmpty } from "@crema/helpers/Common";
import { throwError } from "rxjs";
export const useGetDataApi = (
  url,
  initialData = undefined,
  params = {},
  initialCall = true,
  callbackFun,
) => {
  const [skip, setSkip] = useState(0);
  const { fetchStart, fetchError, fetchSuccess } = useInfoViewActionsContext();
  const [initialUrl, setInitialUrl] = useState(url);
  const [allowApiCall, setAllowApiCall] = useState(initialCall);
  const [loading, setLoading] = useState(initialCall);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isRefreshing, setRefreshing] = useState(false);
  const [apiData, setData] = useState(initialData);
  const [otherData, setOtherData] = useState(null);
  const [queryParams, updateQueryParams] = useState(params);
  const [isResetRequired, setResetRequired] = useState(false);
  let isResponsePending = false;

  const updateInitialUrl = (value) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams) => {
    setLoading(true);
    updateQueryParams({ ...queryParams });
    setAllowApiCall(true);
    if (Array.isArray(initialData)) {
      if (!queryParams.skipReset) {
        setSkip(0);
        setResetRequired(true);
      }
    }
  };

  useEffect(() => {
    const fetchData = () => {
      fetchStart();
      isResponsePending = true;
      if (
        skip === 0 &&
        ((Array.isArray(apiData) && apiData.length === 0) ||
          !Array.isArray(apiData)) &&
        !isResetRequired
      ) {
        setLoading(true);
      }
      if (queryParams.skipReset) {
        setLoading(true);
      }
      let params = {};
      if (!_.isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }
      jwtAxios
        .get(initialUrl, { params: params })
        .then((data) => {
          isResponsePending = false;
          fetchSuccess();
          if (isRequestSuccessful(data.status)) {
            if (Array.isArray(initialData)) {
              setLoadingMore(false);
              setRefreshing(false);
              setData(data?.data?.data);
            } else {
              setData(data?.data?.data);
            }
            setLoading(false);
          } else {
            setLoading(false);
            fetchError(
              data?.data?.error ? data?.data.error : data?.data?.message,
            );
            setData(initialData);
            if (callbackFun) callbackFun(data?.data);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            if (callbackFun) callbackFun(error?.response?.data);
            fetchError(error?.response?.data?.message);
          } else {
            fetchError(error?.message);
            if (callbackFun) callbackFun(error);
          }
          setLoading(false);
        });
    };
    if (allowApiCall && !isResponsePending) fetchData();
  }, [initialUrl, skip, queryParams, allowApiCall, isRefreshing]);
  return [
    {
      loading,
      apiData: apiData,
      otherData,
      skip,
      isLoadingMore,
      isRefreshing,
      initialUrl,
    },
    {
      setSkip,
      setData,
      setLoading,
      setOtherData,
      updateInitialUrl,
      setQueryParams,
      setLoadingMore,
      setRefreshing,
      reCallAPI,
    },
  ];
};

export const trimObjectValues = (obj) => {
  if (_.isEmpty(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  });
  return obj;
};

const handleApiResponse = (url, fetchSuccess, data, resolve, reject) => {
  fetchSuccess?.();
  if (isRequestSuccessful(data?.status)) {
    return resolve(data?.data);
  } else {
    return reject(data?.data);
  }
};

const handleAPIError = (url, fetchSuccess, error, reject) => {
  if (error?.response?.data?.message) {
    return reject(error?.response?.data);
  } else {
    return reject(error);
  }
};

export const postDataApi = (
  url,
  infoViewContext,
  payload,
  isHideLoader = false,
  extraHeaders,
) => {
  // Determine if payload is FormData to adjust headers accordingly
  const isFormData = payload instanceof FormData;
  const { fetchStart, fetchSuccess } = infoViewContext;
  const headers = {
    ...extraHeaders,
    // When payload is FormData, let the browser set Content-Type header for boundary specification
    ...(isFormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" }),
  };
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart?.();
    jwtAxios
      .post(url, payload, headers ? { headers } : {})
      .then((data) => {
        return handleApiResponse(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
  });
};

export const putDataApi = (
  url,
  infoViewContext,
  payload,
  isHideLoader = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, sanitizeData(payload))
      .then((data) => {
        return handleApiResponse(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const getDataApi = (
  url,
  infoViewContext,
  params = {},
  isHideLoader = false,
  headers,
) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .get(url, { params, headers })
      .then((data) => {
        return handleApiResponse(
          url,
          infoViewContext?.fetchSuccess,
          data,
          resolve,
          reject,
        );
        //return data;
      })
      .catch((error) => {
        return handleAPIError(
          url,
          infoViewContext?.fetchSuccess,
          error,
          reject,
        );
      });
  });
};

export const deleteDataApi = (
  url,
  infoViewContext,
  params,
  isHideLoader = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .delete(url, { params })
      .then((data) => {
        return handleApiResponse(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadDataApi = (
  url,
  infoViewContext,
  payload,
  isHideLoader = false,
  onUploadProgress = () => {},
  allowDownload = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .post(url, payload, {
        onUploadProgress,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        responseType: allowDownload ? "arraybuffer" : "stream",
      })
      .then((data) => {
        return handleApiResponse(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadPutDataApi = (
  url,
  infoViewContext,
  payload,
  isHideLoader = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, payload, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((data) => {
        return handleApiResponse(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const downloadDataApi = (
  url,
  infoViewContext,
  params = {},
  isHideLoader = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .get(url, {
        params,
        responseType: "arraybuffer",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        fetchSuccess();
        if (isRequestSuccessful(res.status)) {
          return resolve(res?.data);
        } else {
          const data = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(res?.data)),
          );
          return reject(data);
        }
      })
      .catch((error) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const postRequestLogin = (url, payload) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((data) => {
        if (data?.status === 200 && data?.data) {
          return resolve(data?.data);
        }
        return resolve(data?.data);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const postRequestWithoutLoader = (url, payload, headers) => {
  let headersTemp = headers;
  if (!headersTemp) {
    headersTemp = {
      "content-type": "application/json",
    };
  }
  headersTemp["Authorization"] =
    APP_CONFIG.KEY_JWT + localStorage.getItem(AppStateConstant.ServerToken);
  headersTemp["Accept"] = "*/*";
  return new Promise((resolve, reject) => {
    jwtAxios
      .post(url, payload, {
        headers: headersTemp,
      })
      .then((data) => {
        if (data?.status === 200 && data?.data) {
          return resolve(data?.data);
        }
        return resolve(data?.data);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const getRequestWithoutLoader = (url, headers) => {
  let headersTemp = headers;
  if (!headersTemp) {
    headersTemp = {
      "content-type": "application/json",
    };
  }
  headersTemp["Authorization"] =
    APP_CONFIG.KEY_JWT + localStorage.getItem(AppStateConstant.ServerToken);
  headersTemp["Accept"] = "*/*";
  return new Promise((resolve, reject) => {
    jwtAxios
      .get(url, {
        headers: headersTemp,
      })
      .then((data) => {
        if (data?.status === 200 && data?.data) {
          return resolve(data?.data);
        }
        return resolve(data?.data);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const downloadImageApi = (url, headers) => {
  return new Promise((resolve, reject) => {
    jwtAxios
      .get(url, {
        responseType: "blob",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          ...headers,
        },
      })
      .then((res) => {
        const imageUrl = URL.createObjectURL(res.data);
        return resolve(imageUrl);
      })
      .catch((error) => {
        return handleAPIError(url, {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const useGetPivotData = (
  pivotCountObject,
  pivotPagingObject,
  initialCall,
  isGetAllPivotPaging = false,
) => {
  const isAllowApiFetching = useRef(initialCall);
  const hasMoreItems = useRef(true);
  const currentStartRowRef = useRef(pivotPagingObject.params?.startRow || 0);
  const [loading, setLoading] = useState(true);
  const [isGetAllData, setIsGetAllData] = useState(isGetAllPivotPaging);
  const total = useRef(0);
  const [responseData, setResponseData] = useState([]);
  const [queryParams, updateQueryParams] = useState(pivotPagingObject?.params);

  const infoViewActionsContext = useInfoViewActionsContext();

  const reCallAPI = (params) => {
    params = params || { ...queryParams };
    currentStartRowRef.current = params?.startRow || 0;
    isAllowApiFetching.current = true;
    updateQueryParams(params);
  };

  const setQueryParams = (params) => {
    setLoading(true);
    reCallAPI(params);
  };

  const reFetchData = () => {
    if (pivotPagingObject?.url) {
      fetchData(pivotPagingObject?.url, queryParams);
    }
  };
  const fetchData = (url, queryParams) => {
    infoViewActionsContext.fetchStart();
    setLoading(true);

    const batchSize = 100; // Backend limit per request
    let allData = []; // Accumulator for all fetched data

    // Function to fetch a single batch
    const fetchBatch = (startRow, endRow) => {
      let tempParams = {
        ...queryParams,
        startRow: startRow,
        endRow: endRow,
      };

      return postDataApi(url, infoViewActionsContext, tempParams)
        .then((res) => {
          // Process the response data
          if (res?.data?.data) {
            allData = allData.concat(res?.data?.data);
          }

          const lastRow = res?.data?.lastRow;
          if (lastRow === -1) {
            // More data to fetch
            return Promise.resolve(true); // Continue fetching
          } else {
            // All data fetched
            return Promise.resolve(false); // Stop fetching
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    const fetchAllBatches = (startRow, endRow) => {
      return new Promise((resolve, reject) => {
        const nextBatch = (currentStartRow) => {
          let currentEndRow;
          if (endRow !== undefined) {
            currentEndRow = Math.min(currentStartRow + batchSize - 1, endRow);
          } else {
            currentEndRow = currentStartRow + batchSize - 1 || 0;
          }

          fetchBatch(currentStartRow, currentEndRow)
            .then((shouldContinue) => {
              if (shouldContinue) {
                if (isGetAllData) {
                  // Proceed to next batch
                  nextBatch(currentStartRow + batchSize);
                } else if (
                  endRow !== undefined &&
                  currentStartRow + batchSize <= endRow
                ) {
                  // Proceed to next batch within endRow limit
                  nextBatch(currentStartRow + batchSize);
                } else {
                  // Reached the specified endRow
                  resolve();
                }
              } else {
                // All data fetched
                resolve();
              }
            })
            .catch((error) => {
              // Unexpected error (should not reach here)
              console.error("Unexpected error during fetching:", error);
              reject(error);
            });
        };

        // Start fetching from the initial startRow
        nextBatch(startRow);
      });
    };

    // Main logic
    const initialStartRow = isGetAllData ? 0 : queryParams.startRow || 0;
    const initialEndRow = isGetAllData ? undefined : queryParams.endRow;
    fetchAllBatches(initialStartRow, initialEndRow)
      .then(() => {
        // All data fetched successfully
        setResponseData(allData);
        infoViewActionsContext.fetchSuccess();
      })
      .catch((error) => {
        // Handle any unexpected errors
        console.error("Error during data fetching:", error);
        infoViewActionsContext.fetchError();
        message.error(
          HTTPStatusCodeMessage[error?.response?.status] || "Error",
        );
      })
      .finally(() => {
        infoViewActionsContext.clearInfoView();
        setLoading(false);
      });
  };
  const getTotal = () => {
    if (isNullOrEmpty(pivotCountObject)) {
      return Promise.resolve({ data: 0 });
    }
    postDataApi(pivotCountObject?.url, infoViewActionsContext, queryParams)
      .then((res) => {
        total.current = res?.data;
      })
      .finally(() => {
        fetchData(pivotPagingObject.url, queryParams);
      });
  };

  useEffect(() => {
    if (isAllowApiFetching.current) {
      if (isNullOrEmpty(pivotCountObject?.url)) {
        fetchData(pivotPagingObject.url, queryParams);
      } else {
        getTotal();
      }
    }
  }, []);
  useEffect(() => {
    if (isAllowApiFetching.current) {
      if (isNullOrEmpty(pivotCountObject?.url)) {
        fetchData(pivotPagingObject.url, queryParams);
      } else {
        getTotal();
      }
    }
  }, [queryParams]);

  return [
    {
      loading,
      total: total.current,
      queryParams,
      responseData,
      hasMoreItemsRef: hasMoreItems,
      currentStartRowRef,
      getTotal,
    },
    {
      setQueryParams,
      updateQueryParams,
      reFetchData,
      reCallAPI,
      setLoading,
      setIsGetAllData,
    },
  ];
};
