import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useInfoViewActionsContext } from "@crema/context/AppContextProvider/InfoViewContextProvider";
import { decodeJWT, isNullOrEmpty, safeJsonParse } from "@crema/helpers/Common";
import { AppStateConstant } from "@crema/constants/AppState";
import { signInApi, getUserInfo } from "./AuthApi";
import {
  interceptUnauthorizedRequest,
  setupInterceptor,
} from "@crema/services/axios/ApiConfig";
import { message } from "antd";
import { downloadUserPhotoResponseTypeBlob } from "@crema/api-services/UserApi";
import { USER_DEFAULT_AVATAR_URL } from "@crema/constants/AppConst";
import useIntl from "react-intl/lib/src/components/useIntl";
import { APP_CONFIG } from "@crema/constants/AppConfig";
import { startTransition } from "react";
const DefaultAuthContext = createContext();
const AuthActionsContext = createContext();
export const AppStateContext = createContext();

export const useDefaultAuthContext = () => useContext(DefaultAuthContext);
export const useAuthActionContext = () => useContext(AuthActionsContext);
const convertUserState = (userInfo) => ({
  ...userInfo,
  userRoles: userInfo?.authorities?.map((item) => item.authority) || [],
  phoneNumber: userInfo?.moreInfo?.phoneNumber,
  contactEmail: userInfo?.moreInfo?.contactEmail,
});
const AuthProvider = ({ children }) => {
  const severToken = useRef(null);
  const { messages } = useIntl();
  const isTokenExpired = (token) => {
    if (token === null) return true;
    const decodedToken = decodeJWT(token);
    if (decodedToken) {
      return decodedToken.exp * 1000 < new Date().getTime();
    }
    return true;
  };
  const [dataLogin, setDataLogin] = useState({
    isAuthenticated: !isTokenExpired(
      localStorage.getItem(AppStateConstant.ServerToken),
    ),
    isLoading: true,
  });
  const [appState, setAppState] = useState({});

  const infoViewActionsContext = useInfoViewActionsContext();

  const reFetchUser = () => {
    getUserInfo()
      .then(async (userInfo) => {
        let photoUrl = USER_DEFAULT_AVATAR_URL;
        try {
          if (userInfo?.moreInfo?.userId) {
            photoUrl = await downloadUserPhotoResponseTypeBlob(
              userInfo?.moreInfo?.userId,
            );
          }
        } catch {
          photoUrl = USER_DEFAULT_AVATAR_URL;
        }
        userInfo.moreInfo.photoURL = photoUrl;
        setAppState((prev) => ({
          ...prev,
          idToken: dataLogin?.id_token,
          serverToken: dataLogin?.id_token,
          user: {
            ...convertUserState(userInfo),
          },
        }));
        setDataLogin({
          isLoading: false,
          isAuthenticated: true,
        });
      })
      .catch(() => {
        setDataLogin({
          isLoading: false,
          isAuthenticated: false,
        });
      });
  };

  const updateUserInfo = (userInfo) => {
    setAppState({
      ...appState,
      user: {
        ...appState.user,
        ...userInfo,
      },
    });
  };

  //const clearUrlParams = () => {
  //  const url = window.location.pathname;
  //  window.history.replaceState({}, "", url);
  //};

  const logout = () => {
    startTransition(() => {
      sessionStorage.removeItem(AppStateConstant.ClientToken);
      localStorage.removeItem(AppStateConstant.ServerToken);
      localStorage.removeItem(AppStateConstant.userName);
      localStorage.removeItem(AppStateConstant.appState);
      setAppState({});
      severToken.current = null;
      infoViewActionsContext.clearInfoView();
      setDataLogin({
        isAuthenticated: false,
        isLoading: false,
      });
    });
  };

  const signIn = async ({ email, password }) => {
    setDataLogin({ ...dataLogin, isLoading: true });
    infoViewActionsContext.fetchStart();
    try {
      const dataLogin = await signInApi(email, password);
      if (isNullOrEmpty(dataLogin?.id_token)) {
        setDataLogin({
          ...dataLogin,
          isLoading: false,
        });
        return;
      } else {
        localStorage.removeItem(AppStateConstant.ServerToken);

        localStorage.setItem(AppStateConstant.ServerToken, dataLogin?.id_token);
        localStorage.setItem(AppStateConstant.userName, email);
        severToken.current = dataLogin?.id_token;
        infoViewActionsContext.fetchSuccess();
      }
    } catch (error) {
      infoViewActionsContext.fetchError(error.message);
      message.error(messages[error?.message] || error?.message);
      logout();
    }
  };

  const checkTokenExpiration = () => {
    const tokenStorage = localStorage.getItem(AppStateConstant.ServerToken);

    if (tokenStorage && isTokenExpired(tokenStorage)) {
      logout();
    }
  };

  const userLoggedHandler = () => {
    const appStatStorage = localStorage.getItem(AppStateConstant.appState);
    const tokenStorage = localStorage.getItem(AppStateConstant.ServerToken);
    if (!isNullOrEmpty(JSON.parse(appStatStorage))) {
      const appStateStorageObject = safeJsonParse(appStatStorage);
      setAppState(appStateStorageObject);
    }
    severToken.current = tokenStorage;
    setDataLogin({ ...dataLogin, isLoading: false });
    return;
  };

  useEffect(() => {
    if (!isTokenExpired(localStorage.getItem(AppStateConstant.ServerToken))) {
      userLoggedHandler();
      return;
    } else {
      const appStatStorage = localStorage.getItem(AppStateConstant.appState);
      const tokenStorage = localStorage.getItem(AppStateConstant.ServerToken);
      setDataLogin({ ...dataLogin, isLoading: true, isAuthenticated: false });
      const isExpired = isTokenExpired(tokenStorage);
      if (isExpired) {
        logout();
        return;
      }
      if (!isNullOrEmpty(JSON.parse(appStatStorage))) {
        const appStateStorageObject = safeJsonParse(appStatStorage);
        setAppState(appStateStorageObject);
      }
      severToken.current = tokenStorage;
      setDataLogin({ ...dataLogin, isLoading: false });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(AppStateConstant.appState, JSON.stringify(appState));
  }, [appState]);
  useEffect(() => {
    if (!isNullOrEmpty(severToken.current)) {
      setupInterceptor().then(() => {
        interceptUnauthorizedRequest(logout);
        reFetchUser();
      });
    }
  }, [severToken.current]);
  useEffect(() => {
    const intervalId = setInterval(
      checkTokenExpiration,
      APP_CONFIG.TOKEN_EXPIRED_INTERVAL,
    );
    return () => {
      clearInterval(intervalId);
      logout();
    };
  }, []);
  return (
    <AppStateContext.Provider value={appState}>
      <DefaultAuthContext.Provider
        value={{
          ...dataLogin,
          ...appState,
        }}
      >
        <AuthActionsContext.Provider
          value={{
            logout,
            signIn,
            reFetchUser,
            updateUserInfo,
          }}
        >
          {children}
        </AuthActionsContext.Provider>
      </DefaultAuthContext.Provider>
    </AppStateContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
